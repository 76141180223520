<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-11">
        <p>
          <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. A rem voluptates optio officiis consequatur! Rem facilis consequuntur accusamus, aliquam blanditiis nemo, assumenda, hic harum incidunt ratione tenetur? Dignissimos, maiores placeat! -->
        </p>
        <button v-show="false" type="button" @click="getUserTest">Buscar usuário</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Dashboard',
  methods: {
    /**
     * Ação criada para testar a configuração do bearer token
     */
    async getUserTest () {
      // eslint-disable-next-line
      console.log(await axios.get('/api/user-test'));
    }
  }
};

</script>
